import OnloadHandler from './onload_handler';
//import Rails from "@rails/ujs";
import { initUserPresenceChannel, disconnect } from '../channels/user_presence_channel';

OnloadHandler(function() {
  if (document.getElementById('live-page')) {
    initUserPresenceChannel();
  } else {
    disconnect();
  }
})
