import { Controller } from "stimulus";
import { parseHTMLResponse, showAlert } from '../src/helpers';
export default class extends Controller {
  // static targets = ["question"];

  connect() {
    console.log("controller live streaming connected");
  }

  disconnect() {
    console.log("controller live streaming disconnected");
  }

}
