import OnloadHandler from './onload_handler';

const initBoostrap = function(){
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover({sanitize: false})
  $('[data-toggle="popoverelement"]').popover({
      sanitize: false,
      content: function() {
          let content = $($(this).data('element'));
          return content.length > 0 ? content.html() : '';
      }
  });
  $('.toast').toast({ autohide: false })
  $('#toast').toast('show')  
}

OnloadHandler(function(){
  initBoostrap();

})
