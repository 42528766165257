const OnloadHandler = function(onload_cb, before_visit_cb, visit_cb) {
  document.addEventListener('turbolinks:load', function() {
    if (typeof onload_cb === "function") {
      onload_cb();
    }
  });
  document.addEventListener('turbolinks:before-visit', function() {
    if (typeof before_visit_cb === "function") {
      before_visit_cb();
    }
  });
  document.addEventListener('turbolinks:visit', () => {
    (typeof visit_cb === "function") && visit_cb()
  });
}

export default OnloadHandler
