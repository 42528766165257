import OnloadHandler from './onload_handler';
//landscape alert
  const landscapeAlert = function() {
        if (window.innerHeight > window.innerWidth)
            $("#landscapeMessage").toggle();
        window.addEventListener("orientationchange", function () {
            location.reload()
        });
    }

OnloadHandler(() => {
    landscapeAlert();
})
