export const parseHTMLResponse = function(responseHTML){
  let parser = new DOMParser();
  let responseDocument = parser.parseFromString( `<template>${responseHTML}</template>` , 'text/html');
  let parsedHTML = responseDocument.head.firstElementChild.content;
  return parsedHTML;
}

export const showAlert = function(message){
  let alertElement = parseHTMLResponse($('#generic-message').html());
  $(alertElement).find('.message-body').html(message);
  document.body.appendChild(alertElement);
}

export const showNotice = function(message){
  let alertElement = parseHTMLResponse($('#alert-message').html());
  $(alertElement).find('.message-body').html(message);
  document.body.appendChild(alertElement);
}

