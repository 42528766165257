import {Controller} from 'stimulus';
import {parseHTMLResponse, showAlert, showNotice} from '../src/helpers';
export default class extends Controller {
    appointmentSent(event) {
        const [data, status, xhr] = event.detail;
        let responseElement = parseHTMLResponse(xhr.response);
        let modal = $(this.element).closest('.modal');
        this.element.parentNode.replaceChild(responseElement, this.element);
        modal.modal('hide');
        showAlert("Richiesta appuntamento inviata");
        setTimeout(() => {
            $('.alert').fadeOut();
        }, 3000);
    }
    errorReceived(event) {
        showNotice("Errore nell'invio richiesta appuntamento. Controlla che tutti i campi siano compilati correttamente");
        setTimeout(() => {
            $('.alert').fadeOut();
        }, 3000);
    }
}