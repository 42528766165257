
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"

require("@rails/activestorage").start();
require("channels");

Rails.start()
Turbolinks.start()

import 'bootstrap';
import "@fortawesome/fontawesome-free/css/all"
import '../stylesheets/application/application.scss';

import '../src'


const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

import "controllers"
