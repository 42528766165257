import consumer from "./consumer"

const initUserPresenceChannel = function(object) {
  // console.log(consumer.subscriptions.subscriptions);
  consumer.subscriptions.create("UserPresenceChannel", {
    connected() {
      console.log("UserPresenceChannel.connected");
    },

    disconnected() {
      console.log("UserPresenceChannel.disconnected");
    },

    received(data) {
      console.log("UserPresenceChannel.received", data);
    }
  });
}

function disconnect() {
  if (consumer.subscriptions.subscriptions.length > 0) {
    consumer.disconnect();
    consumer.subscriptions.remove(consumer.subscriptions.subscriptions[0]);
  }
}

export { initUserPresenceChannel, disconnect }
