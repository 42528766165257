import { Controller } from "stimulus";
import { parseHTMLResponse, showAlert } from '../src/helpers';
export default class extends Controller {
 // static targets = ["question"];

  connect() {
    console.log("controller connected");
  }

  disconnect() {
    console.log("controller disconnected");
  }

  messageSent(event) {
    const [data, status, xhr] = event.detail;

    let responseElement = parseHTMLResponse(xhr.response);
    this.element.parentNode.replaceChild(responseElement, this.element);
    showAlert("Messaggio inviato");
    setTimeout(() => {
      $('.alert').fadeOut();
    }, 3000);
  }

  errorReceived(event) {
    const [data, status, xhr] = event.detail;
    let responseElement = parseHTMLResponse(xhr.response);
    this.element.parentNode.replaceChild(responseElement, this.element);
    showAlert("Errore nell'invio del messaggio");
    setTimeout(() => {
      $('.alert').fadeOut();
    }, 3000);
  }
}
