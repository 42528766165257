import {Controller} from 'stimulus';
export default class extends Controller {
    connect() {
        this.load()
        if (this.data.has("refreshInterval") && this.data.get("refreshInterval")) {
            this.startRefreshing();
        }
    }
    disconnect() {
        this.stopRefreshing();
    }
    load() {
        if (!this.data.has("stopCheckbox") || $("#" + this.data.get("stopCheckbox")).is(':checked')) {
            fetch(this.data.get("url"))
                .then(response => response.text())
                .then(html => {
                    this.element.innerHTML = html;
                })
        }
    }
    startRefreshing() {
        let interval = +this.data.get("refreshInterval");
        if (this.data.has("randomInterval")) {
            interval = interval + Math.floor(Math.random() * this.data.get("randomInterval"))
        }
        this.refreshTimer = setInterval(() => {
            this.load();
        }, interval)
    }
    stopRefreshing() {
        if (this.refreshTimer) {
            clearInterval(this.refreshTimer);
        }
    }
}
