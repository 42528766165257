import OnloadHandler from './onload_handler';
class CountDown {
  constructor(elm, countDownDate) {
    this.elm = elm;
    this.countDownDate = new Date(parseFloat(countDownDate)).getTime();
    this.daysSpan = $('#days');
    this.hoursSpan = $('#hours');
    this.minutesSpan = $('#minutes');
    this.secondSpan = $('#seconds');
    this.updateClock();
    this.timeinterval = setInterval(() => {
      this.updateClock();
    }, 1000);
  }
  
  updateClock() {
    const distance = this.getTimeRemaining();
    if (distance.total <= 0) {
      this.clearUpdating();
      this.elm.hide();
    } else {
      this.daysSpan.html(distance.days);
      this.hoursSpan.html(('0' + distance.hours).slice(-2));
      this.minutesSpan.html(('0' + distance.minutes).slice(-2));
      this.secondSpan.html(('0' + distance.seconds).slice(-2));
    }
  }

  getTimeRemaining() {
    const distance = this.countDownDate - Date.now();
    
    return {
      total: distance,
      days: Math.floor(distance / (1000 * 60 * 60 * 24)),
      hours: Math.floor((distance / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((distance / 1000 / 60) % 60),
      seconds: Math.floor((distance % (1000 * 60)) / 1000)
    };
  }

  clearUpdating() {
    clearInterval(this.timeinterval);
  }
}

OnloadHandler(() => {
  const countDownDiv = $("#clockdiv");
  const countDownDate = $("#clockdiv").data("event");
  const countDown = new CountDown(countDownDiv, countDownDate);
})
